// import {calculationRelevant} from "@/store/decorators";
import {CarriageObj, CostingObj, FinishingObj, InspectionObj, OtherObj, PackingObj} from "@/store/CostingObj";
import {isNumeric} from "@/mixins";
import {nextTick} from "vue";
import store from "@/store";
import Cookie from "js-cookie";


export class SpringCostingObj extends CostingObj {
    MaterialCost = null
    WireSource = null  //  2, is stock, 3 is on order
    FMMaterialCost = null
    PartCost = null
    PriceByKg = 'K'
    FMStockMaterial = null
    CostingMethod = 3 // default margins
    ProductionRate = null   // how many can we make in an hour?
    HourRate = null

    constructor(part) {
        super();
        this.Part = part;
        this.Agro = new AgroObj()
        this.Carriage = new CarriageObj()
        this.Coiling = new CoilingObj()  // all springs must be coiled
        this.Finishing = new FinishingObj()
        this.Heat = new HeatObj()
        this.Inspection = new InspectionObj()
        this.Other = new OtherObj()
        this.Packing = new PackingObj()
        this.Peening = new PeeningObj()
        this.AncillaryItems = []  // empty list to add object to
        if (part.PartType === 'C') {
            this.Scragging = new ScraggingObj()
            this.Grinding = new GrindingObj()
        }
        this.Results.stock_msg = 'Spring values calculated using IST data'
    }
    // ********************************************************

    checkCostingViable() {
        var errors = "";

        if (this.Part.PartType === 'X') { // bought in parts
            return 1;
        } // end of  parts test

        if (this.Part.PartType === 'L') {
            // we need sheet dimensions, cost per sheet, qty per sheet, setup & run time
            // console.log("PARTS on 434", this.parts[0])
            if (this.parts[0]['itemLength'] &&
                this.parts[0]['itemWidth'] &&
                this.parts[0]['itemThickness'] &&
                this.parts[0]['material']) {

                var sheet_volume = this.Sheet_weight = this.parts[0]['itemLength']
                    * this.parts[0]['itemWidth'] * this.parts[0]['itemThickness']
                    / (1000 * 1000 * 1000)  // m3

                if (this.parts[0]['material'] === 'Stainless Steel') {
                    this.Sheet_weight = sheet_volume * 7500  // density = 7500kg/m3
                } else { // Mild Steel
                    this.Sheet_weight = sheet_volume * 7840
                }

                if (this.parts[0].qty_per_sheet) {
                    // assume 5% wastage
                    var weight = 0.95 * this.Sheet_weight / this.parts[0]['qty_per_sheet']
                    this.parts[0]['itemWeight'] = weight.toFixed(4)
                }
                this.Sheet_weight = this.Sheet_weight + 'kg'

                if (this.parts[0]['itemCost'] &&
                    this.parts[0]['qty_per_sheet'] &&
                    this.parts[0]['setup_time'] &&
                    this.parts[0]['run_time']) {
                    // console.log("laser cut is valid")
                    return 1
                }
            } else {
                return 0
            }
        } // end of laser cut parts test

        if (this.Part.PartType === 'W') { // wireform
            if (this.Part.WFStructure.length > 0) {
                return 1;
            }
        } // end of wireform test

        if (this.Part.PartType === 'C') {
            if (!this.Part.Results.helix_angle && !this.Part.WireGrade && this.Part.WireGrade > 0) {
                console.error('here we are')
                return 1;
            } else {
                if (this.Part.WireGrade === false || this.Part.WireGrade === 0) {
                    errors = "Wire grade has not been set";
                }
            }
        } // end of compression tests

        if (this.Part.PartType === 'E') {
            if (this.Part.ExtEstimatedLength && this.Part.WireGrade && this.Part.WireGrade > 0) {

                return 1;
            } else {
                if (this.Part.WireGrade === false || this.Part.WireGrade === 0) {
                    errors = "Wire grade has not been set";
                }
            }
        } // end of extension tests

        if (this.Part.PartType === 'T') {
            //results['body_length']
            console.log(this.Part.TotalCoils, this.Part.WireGrade, this.Part.WireGrade)
            if (this.Part.TotalCoils && this.Part.WireGrade && this.Part.WireGrade > 0) {
                console.log("costing viable for torsion")
                return 1;
            } else {
                if (this.Part.WireGrade === false || this.Part.WireGrade === 0) {
                    errors = "Wire grade has not been set";
                }
            }
        } // end of extension tests

        if (errors.length > 0) {
            console.log("Costing has errors", errors)
            // var thisTitle = 'Costing Validation';
            // scope.callDialog(thisTitle, errors);
        }
        console.log("error check", errors)
        return 0;
    }  // end of checkCostingViable

    async validateCosting(opt, max_dp) {
        await nextTick();
        console.log("validating costing 113", this.Part.Results)
        // if laser part, calculate no of sheets required
        // lose any spare decimal places
        if (opt && max_dp !== undefined) {
            this[opt] = Math.round(this[opt] * Math.pow(10, max_dp)) / Math.pow(10, max_dp);
        }

        var cost_check = this.checkCostingViable()
                console.log("costingObj, checking viable", this.Qty1, cost_check)
        if (cost_check === 0) {  // why was this 1? 31/7/24
            // clear out any quantities that may have had values in but are now empty
            for (var i = 1; i < 6; i++) {
                // quantities
                if (this['Qty' + i] == null || this['Qty' + i] === 0 ||
                    this['Qty' + i].length === 0 || !isNumeric(this['Qty' + i])) {
                    this['Qty' + i] = null;
                }
                // watch out for deleted cells in Sellcost 1-5
                if (this['SellCost' + i] == null || this['SellCost' + i] === 0 ||
                    this['SellCost' + i].length === 0 || !isNumeric(this['SellCost' + i])) {
                    this['SellCost' + i] = null;
                }
                if (this.Peening['PeenCost' + i] == null || this.Peening['PeenCost' + i] === 0 ||
                    this.Peening['PeenCost' + i].length === 0 || !isNumeric(this.Peening['PeenCost' + i])) {
                    this.Peening['PeenCost' + i] = null;
                }
                if (this['HeatCost' + i] == null || this['HeatCost' + i] === 0 ||
                    this['HeatCost' + i].length === 0 || !isNumeric(this['HeatCost' + i])) {
                    this['HeatCost' + i] = null;
                }

                if (this['FinishingCost' + i] == null || this['FinishingCost' + i] === 0 ||
                    this['FinishingCost' + i].length === 0 || !isNumeric(this['FinishingCost' + i])) {
                    this['FinishingCost' + i] = null;
                }
            }

            // let dataWaiting = true;
            let costingURL = `/sales/calculate_spring_costs/`;
            if (this.Qty1) {
                const site = store.getters.CurrentSite;
                console.log("SITE BEFORE COST", site)
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'X-CSRFToken': Cookie.get('csrftoken') ?? '',
                    },
                    //credentials: 'include',
                    //mode: 'cors',
                    body: JSON.stringify({costing: this, 'site': site})
                };
                await fetch(costingURL, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        console.log("BACK FROM CALCULATING COSTS")
                        //Update ourselves with the new values
                        for (const [property, value] of Object.entries(data.return_costing)) {
                            if (value === null) continue; //Don't null out fields
                            this[property] = value
                        }
                        this.Coiling = data.return_coiling
                        if (data.return_scragging) {
                            this.Scragging = data.return_scragging
                        }
                        if (data.return_grinding) {
                            this.Grinding = data.return_grinding
                        }
                        if (data.return_peening) {
                            this.Peening = data.return_peening
                        }
                        if (data.return_heat) {
                            this.Heat = data.return_heat
                        }
                        // if (data.return_finishing) {
                        //     this.Finishing = data.return_finishing
                        // }
                        // inspection
                        // packing
                        // if (data.return_carriage) {
                        //     this.Carriage = data.return_carriage
                        // }
                        // if (data.return_agro) {  // NOT USING THIS ONE ANY MORE
                        //     this.Agro = data.return_agro
                        // }
                        // Ops
                        // Parts
                        //
                        this.Results = data.costing_results

                    });
            }
        }
    }    // end of validate costing

}  // end of costingObj

// *********************************************************
export class AgroObj {
    // NOT USED IN E8
    ConfirmAgro = false
    AgroSetup = null
    AgroRate = null
}
export class CoilingObj {
    CoilingMachine = null
    SetupManual = null
    RunningManual = null
}

export class ScraggingObj {
    ConfirmScragging = false
    ForceScragging = false
    SetupManualCost = null
    RunningManualCost = null
}

export class GrindingObj {
    ConfirmGrinding = false
    GrindingMachine = 'none'
    SetupManualCost = null
    RunningManualCost = null
}

export class PeeningObj {
    ConfirmPeening = false
    ForcePeening = false
    PeeningTime = null
    PeeningRate = null
    ManualPeening = false
    ManualPeenCost1 = null
    ManualPeenCost2 = null
    ManualPeenCost3 = null
    ManualPeenCost4 = null
    ManualPeenCost5 = null
}

export class HeatObj {
    ConfirmHeat = false
    ManualHeat = false
    HeatTime = null
    HeatRate = null
    ManualHeatCost1 = null
    ManualHeatCost2 = null
    ManualHeatCost3 = null
    ManualHeatCost4 = null
    ManualHeatCost5 = null
}

