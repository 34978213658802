import {CostingObj} from "@/store/CostingObj";
import store from "@/store/index";
import Cookie from "js-cookie";

export class LasercutCostingObj extends CostingObj {
    sheet_cost = null
    setup_time = null
    run_time_sheet = null
    sheets_required = null
    lasercut_margin = null
    hourly_rate = null
    lasercut_hourly_rate = null
    op1_rate = null
    op1_cost = null
    op2_rate = null
    op2_cost = null
    op3_rate = null
    op3_cost = null
    op4_rate = null
    op4_cost = null
    op5_rate = null
    op5_cost = null
    op6_rate = null
    op6_cost = null

    async validateCosting(/*opt, max_dp*/) {
        console.debug('Laser cut validate here on line 25', [...arguments])

        if (this.Part.Sheet_length &&
            this.Part.Sheet_width &&
            this.Part.Sheet_thickness &&
            this.Part.PartMaterial) {

            let sheet_volume = this.Part.Sheet_weight = this.Part.Sheet_length
                * this.Part.Sheet_width * this.Part.Sheet_thickness
                / (1000 * 1000 * 1000)  // m3

            if (this.PartMaterial === 'Stainless Steel') {
                this.Part.Sheet_weight = sheet_volume * 7500  // density = 7500ks/m3
            } else { // Mild Steel
                this.Part.Sheet_weight = sheet_volume * 7840
            }

            if (this.Part.qty_per_sheet) {
                // assume 5% wastage
                this.Part.Sheet_weight = 0.95 * this.Part.Sheet_weight / this.Part.qty_per_sheet
            }
            this.Part.Sheet_weight = this.Part.Sheet_weight.toFixed(2)

            if (this.sheet_cost &&
                this.Part.qty_per_sheet &&
                this.setup_time &&
                this.run_time_sheet &&
                this.Part.Specification &&
                this.Part.Supplier) {
                this.Part.cutting_time
                console.log("laser cut is valid")
                if (this.Qty1) {
                    let costingURL = `/sales/calculate_laser_costs/`;
                    const site = store.getters.CurrentSite;
                    console.log("SITE BEFORE COST in Lasercut.vue", site)
                    console.log("this in lasercut 381", this)
                    const requestOptions = {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'X-CSRFToken': Cookie.get('csrftoken') ?? '',
                        },
                        //credentials: 'include',
                        //mode: 'cors',
                        body: JSON.stringify({costing: this, 'site': site})
                    };
                    fetch(costingURL, requestOptions)
                        .then(response => response.json())
                        .then(data => {
                            //Update ourselves with the new values
                            console.log('return on 396', data)
                            for (const [property, value] of Object.entries(data.return_costing)) {
                                this[property] = value
                            }

                            if (data.return_finishing) {
                                this.Finishing = data.return_finishing
                            }
                            if (data.return_carriage) {
                                this.Carriage = data.return_carriage
                            }
                            // if (data.return_agro) {
                            //     this.Agro = data.return_agro
                            // }
                            // packing
                            // inspection

                            this.Results = data.costing_results

                        });
                }
                return 1
            } else {
                console.log("laser costing invalid")


            }
        }
    }
}