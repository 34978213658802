import {calculationRelevant} from "@/store/decorators";
import {isNumeric} from "@/mixins";
import {
    // CostingObj,
    // CoilingObj,
    // ScraggingObj,
    // GrindingObj,
    // PeeningObj,
    // HeatObj,
    // FinishingObj,
    // CarriageObj,
    // AgroObj, InspectionObj, PackingObj
} from "@/store/SpringCostingObj";
import {nextTick} from "vue";
// https://github.com/convert-units/convert-units
import configureMeasurements, {length, force} from 'convert-units';
import {PartObj} from "@/store/PartObj";
import Cookie from "js-cookie";

// import SpringOperatingPos from "@/components/common/SpringOperatingPos.vue";

export class SpringObj extends PartObj {
    @calculationRelevant
    Units = 'M'     // metric by default
    @calculationRelevant
    DesignStandard = null // british standards, or other
    @calculationRelevant
    DesignMethod = 'IST'        // all we do
    @calculationRelevant
    DesignMode = null // which parameters are used to determine the design
    LockOptions = null  // if we have a designed spring, lock the options
    @calculationRelevant
    DesignFields = []   //what did we use to define the spring ?
    @calculationRelevant
    WireSection = 'C'   // we only use circular wire
    @calculationRelevant
    MaterialType = null   //type of material, e.g. stainless steel, patented carbon steel, etc.
    @calculationRelevant
    SpringMaterial = null
    WireSize = null   // for lookup to ISTdata - WireDiameter is the physical size
    @calculationRelevant
    WireDiameter = null   //to allow for non-standard wire sizes
    @calculationRelevant
    WireGrade = null  //  grade of wire, essential for pricing
    GradeDescription = null // display purposes
    @calculationRelevant
    CustomTensile = null    // used for wire specification & tolerances
    WireGradeCritical = false   //  used to make sure we always use this wire grade in future runs
    @calculationRelevant
    CoilDiameterType = 'OD'   // ID, OD or MD
    lastCoilDiameterType = 'OD' // used for when the user changes to a different coil diameter type
    @calculationRelevant
    CoilDiameter = null
    CoilDiameter1 = null //only used for conical NOT IMPLEMENTED
    @calculationRelevant
    SpringRate = null
    @calculationRelevant
    TotalCoils = null

    @calculationRelevant
    CoilDirection = true // True = rights, false = left
    @calculationRelevant
    EndType1 = null   // leg type for Torsion
    @calculationRelevant
    EndType2 = null
// compression only options
    @calculationRelevant
    DeadCoils = null
    @calculationRelevant
    FreeLength = null
    @calculationRelevant
    CompTipThickness = null
    @calculationRelevant
    CompEndFixing = null
    @calculationRelevant
    RequiredSolidLength = null

// extension only options
    @calculationRelevant
    FreeLengthType = null
    @calculationRelevant
    ExtInitialTension = null
    @calculationRelevant
    ExtLoopOD = null
    @calculationRelevant
    ExtLoopOption = true  // True = match body, false = custom value

// torsion only options
    @calculationRelevant
    TorsLeg1Length = null
    @calculationRelevant
    TorsLeg2Length = null
    @calculationRelevant
    TorsBodySupport = null
    @calculationRelevant
    TorsMaxDeflection = null
    @calculationRelevant
    TorsMoment = null
// somewhere to link the costing
    Costing = null
// somewhere to add notes
    ToleranceNotes = [{}]
// flags for what details appear on shop floor print out.  Set the smarties on screen
    quoteflags = {
        sfEnds: true,
        sfFinish: true,
        sfDeadCoils: false,
        sfTip: false,
        sfPitch: false,
        sfTotalCoils: false,
        sfLength: 0,
        // sfLengthTol: false,
        sfSolidLength: false,
        sfCoilDiameter: 0,
        // sfCoilDiameterTol: false,
        sfRate: 0,
        // sfRateTol: false,
        sfSquareness: false,
        sfParallelism: false,
        sfLoopOD: false,
        sfInitialTension: false,
        sfFreeLengthType: false,
        sfLenLength: false,
        sfLoads: false,
        sfTension: false,
        sfMaterial: false,
        sfMaterialSpec: true,
        sfEndFixing: false,
        sfTipThickness: false,
        sfWireDiameter: 0,
        // sfWireDiameterTol: false,
        sfCoilType: false,
        sfBodyLength: false,
        sfLoopType: false,
        sfLegs: false,
        sfLegLength: false,
        sfBodySupport: false,
        sfWeight: false,
        sfODexpansion: false,
        sfOP: 0,
        // sfOPTol: false,
        sfGrade: false,
        sfDirection: false,
// for quotes
        qMaterial: false,
        qEnds: true,
        qFinish: true,
        qDeadCoils: false,
        qTip: false,
        qPitch: false,
        qTotalCoils: false,
        qLength: 0,
        // qLengthTol: false,
        qSolidLength: false,
        qBodyLength: false,
        qCoilDiameter: 0,
        // qCoilDiameterTol: false,
        qRate: 0,
        // qRateTol: false,
        qSquareness: false,
        qParallelism: false,
        qFrequency: false,
        qLoopOD: false,
        qInitialTension: false,
        qFreeLengthType: false,
        qLegs: false,
        qLoads: false,
        qTension: false,
        qEndFixing: false,
        qTipThickness: false,
        qWireDiameter: 1,
        // qWireDiameterTol: false,
        qCoilType: false,
        qLoopType: false,
        qLegType: false,
        qLegLength: false,
        qBodySupport: false,
        qWeight: false,
        qODexpansion: false,
        qOP: 0,
        // qOPTol: false,
        qGrade: false,
        qDirection: false,
    }
    DesignErrors = null
    Tolerances = {}
    SpringCustomTolerance = {}
    Results = {};
    Tensile_Opts = {}
    springrequirements = new springrequirements();

    setSpringDefaults() {
        this.PartNo = ''
        this.ToleranceNotes = []
        this.ToleranceNotes.push(new Tolerance_Notes())
        switch (this.PartType) {
            case 'C':
                this.DesignStandard = 'BS 1726-1'
                this.EndType1 = 'CG'
                this.EndType2 = null
                this.DeadCoils = 1.5
                this.CompTipThickness = 30
                this.CompEndFixing = 'NS'
                this.FreeLengthType = null
                this.RequiredSolidLength = null
                this.ExtInitialTension = null
                this.ExtLoopOD = null
                this.ExtLoopOption = true  // True = match body, false = custom value
// torsion only options
                this.TorsLeg1Length = null
                this.TorsLeg2Length = null
                this.TorsBodySupport = null
                this.TorsMaxDeflection = null
                this.TorsMoment = null
                break
            case 'E':
                this.DesignStandard = 'BS 1726-2'
                this.EndType1 = 'ML'
                this.EndType2 = null
                this.DeadCoils = null
                this.CompTipThickness = null
                this.CompEndFixing = null
                this.FreeLengthType = 'insideloops'
                this.RequiredSolidLength = null
                this.ExtInitialTension = null
                this.ExtLoopOD = null
                this.ExtLoopOption = true  // True = match body, false = custom value
// torsion only options
                this.TorsLeg1Length = null
                this.TorsLeg2Length = null
                this.TorsBodySupport = null
                this.TorsMaxDeflection = null
                this.TorsMoment = null
                break
            case 'T':
                this.DesignStandard = 'BS 1726-3'
                this.EndType1 = 'NA'
                this.EndType2 = null
                this.DeadCoils = null
                this.CompTipThickness = null
                this.CompEndFixing = null
                this.FreeLengthType = null
                this.RequiredSolidLength = null
                this.ExtInitialTension = null
                this.ExtLoopOD = null
                this.ExtLoopOption = true  // True = match body, false = custom value
// torsion only options
                this.TorsLeg1Type = 'T'
                this.TorsLeg2Type = 'T'
                this.TorsLeg1Length = null
                this.TorsLeg2Length = null
                this.TorsBodySupport = 'notknown'
                this.TorsMaxDeflection = null
                this.TorsMoment = null
                break
        }
    }

    convert_to_metric() {
        console.log("CONVERT TO METRIC", this.DesignFields, this)
        const convert = configureMeasurements({
            length, force,
        });
        console.log("converting on line 280", this['DesignFields'])
        // focus on the design fields, everything else will be calculated
        this.DesignFields.forEach(el => {
            console.log("converting", el, this[el])
            switch (el) {
                case 'WireDiameter':
                case 'CoilDiameter':
                case 'FreeLength':
                    this[el] = +convert(this[el]).from('in').to('mm').toFixed(4);
                    break;
                case 'SpringRate':
                    this[el] = +(this[el] / 5.7103).toFixed(4); // N/mm - Lbf/inch
                    break;
            }
            console.log("converted", this[el])
        })
    }

    containsItem(item) {

        // const numeric_items = ['body_length', 'body_length_max', 'bore_tolerance', 'CoilDiameter',
        //     'coil_tolerance', 'ExtLoopID', 'FreeLength', 'inside_coil_diameter', 'Length', 'mandrel_diameter',
        //     'max_od_expansion', 'mean_coil_diameter', 'min_length_static', 'min_length_dynamic', 'ODExpansion',
        //     'outside_coil_diameter', 'pitch', 'RequiredSolidLength', 'rod_tolerance', 'solid_length', 'spring_weight',
        //     'tube_diameter', 'wire_length', 'WireDiameter', 'wire_tolerance', 'SpringRate', 'spring_rate',
        //     'solid_stress', 'initial_tension_stress', 'solid_load', 'initial_tension_stress', 'solid_load']
        const core_items = ['CoilDiameter', 'CoilDiameter1', 'CompTipThickness', 'DeadCoils',
            'ExtInitialTension', 'ExtLoopOD', 'FreeLength', 'RequiredSolidLength', 'SpringRate', 'TotalCoils',
            'WireDiameter']
        let i;
        for (i = 0; i < core_items.length; i++) {
            if (core_items[i] === item) {
                return true;
            }
        }
        return false;
    }

    convert_to_numeric(prop, val) {
        // used to stop Vue moaning at Decimal strings
        // console.log(obj)

        // console.log("converting property to number", property, obj[property]);
        if (prop === 'springrequirements') {
            console.log("sort out spring requirements")
        } else if (prop === 'Tensile_Opts') {
            console.log("sort out Tensile_Opts")
        } else if (prop === 'springcustomtolerance' || prop === 'SpringCustomTolerance') {
            console.log("sort out springcustomtolerance ")
        } else if (prop === 'Results') {
            console.log("sort out Results")
        } else {
            if (this.containsItem(prop)) {
                val = +val;
            }
        }
        return val
    }

    convert_to_imperial(obj) {
        console.log(obj)
        const convert = configureMeasurements({
            length, force,
        });

        for (const property of Object.keys(obj)) {
            console.log("converting property", property, obj[property]);
            // let old;
            switch (property) {
                case 'body_length':
                case 'body_length_max':
                case 'bore_tolerance':
                case 'CoilDiameter':
                case 'coil_tolerance':
                case 'ExtLoopID':
                case 'FreeLength':
                case 'inside_coil_diameter':
                case 'Length':
                case 'mandrel_diameter':
                case 'max_od_expansion':
                case 'mean_coil_diameter':
                case 'min_length_static':
                case 'min_length_dynamic':
                case 'ODExpansion':
                case 'outside_coil_diameter':
                case 'pitch':
                case 'RequiredSolidLength':
                case 'rod_tolerance':
                case 'solid_length':
                case 'spring_weight':
                case 'tube_diameter':
                case 'wire_length':
                case 'WireDiameter':
                case 'wire_tolerance':
                    // old = obj[property];
                    console.log("converting -->", property, obj[property])
                    obj[property] = +convert(+obj[property]).from('mm').to('in').toFixed(4);
                    if (obj[property] <= +0) {
                        obj[property] = null
                    }
                    console.log("converting -->", obj[property])
                    break;
                case 'solid_load':
                    obj[property] = +convert(+obj[property]).from('N').to('lbf').toFixed(4);
                    break;
                case 'SpringRate':
                case 'spring_rate':
                    console.log("converting spring rate", obj[property])
                    obj[property] = +(+obj[property] * 5.7103).toFixed(4); // N/mm - Lbf/inch
                    if (obj[property] <= +0) {
                        obj[property] = null
                    }
                    break;
                case 'solid_stress':
                case 'initial_tension_stress':
                    obj[property] = +obj[property] * 145.037738; // N/mm2 - Lbf/inch2
            }
        }
    }

    async calculateSpring() {
        // if designed in Imperial, run the conversion now
        if (this.Units === 'I') {
            this.convert_to_metric()
        }
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'X-CSRFToken': Cookie.get('csrftoken') ?? '',
            },
            body: JSON.stringify(this)
        };
        return fetch(`/common/calculateSpring/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                // this is ok 2024/09/02
                console.log("return from calculateSpring", data)
                //Update ourselves with the new values
                for (const [property, value] of Object.entries(data['return_spring'])) {
                    // stop Vue from grumbling at number strings...
                    this[property] = this.convert_to_numeric(property, value);
                }
                // TODO: is this right?
                // springrequirements are the operating positions
                this.springrequirements = data['return_spring']['springrequirements'];

                // stop Vue from grumbling at number strings...
                if (this.Units === 'I') {
                    this.convert_to_imperial(this)
                    this.convert_to_imperial(this.Results)
                    this.convert_to_imperial(this.Tolerances)
                    if (this.PartType === 'C') {
                        this.Tolerances.squareness[0] = (+this.Tolerances.squareness[0] / 25.4).toFixed(4);
                        this.Tolerances.squareness[1] = (+this.Tolerances.squareness[1] / 25.4).toFixed(4);
                        this.Tolerances.parallelism[0] = (+this.Tolerances.parallelism[0] / 25.4).toFixed(4);
                        this.Tolerances.parallelism[1] = (+this.Tolerances.parallelism[1] / 25.4).toFixed(4);
                    }
                }
                // end of TODO
            });
    }


    check_build_rules(element) {
        /*
        according to the IST software, for compression springs we can have:
        option 1: free length + 3 parameters (from wire diameter, coil diameter, total coils, spring rate)
        option 2: free length + 2 parameters + 1 load/length
        option 3: spring rate  + 2 parameters + 1 load/length
        option 4:  + 2 parameters + 2 load/length
        this message is repeated in spring_calculations.py
         */
        let errors_flag = 0;
        let errors = '';
        let warnings = '';

        console.log("checking build rules", element, this)
        if (this.WireGrade === 9999 && !this.CustomTensile &&
            this.PartType !== 'W' && this.PartType === 'X' && this.PartType === 'L') {
            // custom tensile not set, can't continue
            errors_flag = 2;
            errors = 'Invalid Grade';
            console.log("return on 428")
            return [errors_flag, errors, warnings]  // show stopper
        }

        if (this.CoilDiameter && this.WireDiameter) {
            // TODO: make aware of the standard in place
            if (this.CoilDiameter <= (2 * this.WireDiameter)) {
                warnings = warnings + ' Coil Diameter must be at least 2 x Wire Diameter';
            }
            if (this.Units === 'M') {
                if (this.CoilDiameter < 3 || this.CoilDiameter > 160) {
                    warnings = warnings + "Coil diameter must be > 3mm and less than 160mm"
                }
            } else {
                if (this.CoilDiameter < 0.118 || this.CoilDiameter > 6.3) {
                    warnings = warnings + "Coil diameter must be > 0.118 inch and less than 6.3 inch"
                }
            }
            // TODO: repeat in imperial
        }

        if (this.WireDiameter) {
            if (this.Units === 'M') {
                if (this.WireDiameter < 0.2007874) {
                    errors_flag = 2;
                    errors = errors + ' Wire Diameter must be at least 0.2mm'
                    return [errors_flag, errors, warnings]
                }
            } else {  // imperial
                if (this.WireDiameter < 0.0787) {
                    errors_flag = 2;
                    errors = errors + ' Wire Diameter must be at least 0.2mm'
                    return [errors_flag, errors, warnings]
                }
            }
        }

        // total coils
        if (this.TotalCoils) {
            if (this.TotalCoils < 3.5) {
                warnings = warnings + ' Total Coils is less than standard';
                // warning not an error
            }
            if (this.TotalCoils < 1) {
                errors = errors + "You need at least 1 coil to make a spring!";
                errors_flag = 2;
                console.log("return on 473 show stopper")
                return [errors_flag, errors, warnings]  // show stopper
            }
        }

        if (this.WireDiameter && this.TotalCoils && this.FreeLength) {
            if ((this.WireDiameter * this.TotalCoils) > this.FreeLength) {
                errors = errors + "Number of coils does not allow this length.";
                errors_flag = 2;
                console.log("return on 483")
                return [errors_flag, errors, warnings]  // show stopper
            }
        }

        // must be greater than solid length
        if (this.WireDiameter
            && this.TotalCoils
            && this.CompFreeLength) {
            if ((this.WireDiameter * this.TotalCoils) > this.CompFreeLength) {
                errors = errors + "Free Length less than wire parameters allow";
                errors_flag = 2;
                console.log("return on 495")
                return [errors_flag, errors, warnings]  // show stopper
            }
        }

        if (this.PartType === 'E' && this.ExtFreeLength
            && !this.ExtLoopOD) {
            errors_flag = 2;
            errors = errors + ' External Loop OD cannot be blank';
            console.log("return on 504")
            return [errors_flag, errors, warnings]  // show stopper
        }

        // freelength - compression
        if (element === 'FreeLength' && this.FreeLength) {
            // warnings not errors
            if (this.Units === 'M') {
                if (this.FreeLength > 630) {
                    warnings = warnings + ' Free length exceeds standard (630mm)';
                }
                if (this.FreeLength > 30000) {
                    warnings = warnings + "maximum manufacturing capability is 30m";
                }
                if (this.FreeLength < 3) {
                    warnings = warnings + "minimum manufacturing capability is 3mm";
                }
            } else {
                if (this.FreeLength > 24.8) {
                    warnings = warnings + ' Free length exceeds standard (630mm)';
                }
                if (this.FreeLength > 1181) {
                    warnings = warnings + "maximum manufacturing capability is 30m";
                }
                if (this.FreeLength < 0.118) {
                    warnings = warnings + "minimum manufacturing capability is 3mm";
                }
            }

        }

        if (element === 'ExtFreeLength' && this.ExtFreeLength) {
            // warnings not errors
            if (this.ExtFreeLength > 630) {
                warnings = warnings + ' Free length exceeds standard (630mm)';
            }
            if (this.ExtFreeLength > 30000) {
                warnings = warnings + "maximum manufacturing capability is 30m";
            }
            if (this.ExtFreeLength < 3) {
                warnings = warnings + "minimum manufacturing capability is 3mm";
            }
            // must be greater than solid length
        }

        // coil diameter
        if (element === "CoilDiameter" && this.CoilDiameter) {
            // warnings not errors
            if (this.Units === 'M') {
                if (this.CoilDiameter > 160) {
                    warnings = warnings + ' Coil Diameter exceeds standard (160mm)';
                }
                if (this.CoilDiameter > 400) {
                    warnings = warnings + "maximum manufacturing capability is 400mm";
                }
                if (this.CoilDiameter < 1) {
                    warnings = warnings + "minimum manufacturing capability is 1mm";
                }
            } else {
                if (this.CoilDiameter > 6.3) {
                    warnings = warnings + ' Coil Diameter exceeds standard (160mm)';
                }
                if (this.CoilDiameter > 15.74) {
                    warnings = warnings + "maximum manufacturing capability is 400mm";
                }
                if (this.CoilDiameter < 0.04) {
                    warnings = warnings + "minimum manufacturing capability is 1mm";
                }
            }

        }

        // dead coils
        if (element === 'DeadCoils' && this.DeadCoils) {
            // warnings not errors
            if (this.DeadCoils > 6) {
                warnings = warnings + ' Really? That many dead coils?';
            }
            if (isNumeric(this.TotalCoils) && this.DeadCoils > this.TotalCoils) {
                errors = errors + ' Dead coils cannot be greater than total coils';
                errors_flag = 2
                console.log("return on 585")
                return [errors_flag, errors, warnings];
            }
        }

        // wire size
        if (element === 'WireDiameter' && this.WireDiameter) {
            // warnings not errors
            if (this.Units === 'M') {
                if (this.WireDiameter > 26) {
                    warnings = warnings + ' Wire Diameter is above maximum allowed (26mm)';
                }
                if (this.WireDiameter < 0.65) {
                    warnings = warnings + ' Wire Diameter is below standard (0.65mm)';
                }
            } else {
                // TODO: CHECK THIS
                if (this.WireDiameter > 21.02) {
                    warnings = warnings + ' Wire Diameter is above maximum allowed (26mm)';
                }
                if (this.WireDiameter < 0.026) {
                    warnings = warnings + ' Wire Diameter is below standard (0.65mm)';
                }
            }

        }

        // tip thickness > 0 < 100

        // specified stress
        // right ball park

        // if we got here then there are no significant errors
        console.log("return on 617")
        return [errors_flag, errors, warnings];

    }// end of checkBuildRules

    async validateSpring(prop = null, val = null) {
        console.log("validate 606", prop, val)
        //Blur events on PrimeVue InputNumbers run before the value has updated
        //So we'll wait for the value to magic its way through Vue before trying to use it
        //https://github.com/primefaces/primevue/issues/806
        await nextTick();
        if (!this.SpringMaterial) {
            return // can't build a spring without a material
        }
        console.log("validating spring", prop, val)
        if (prop === 'CoilDiameter' && this.PartType === 'E' && this.ExtLoopOption && this.WireDiameter) {
            switch (this.CoilDiameterType) {
                case 'OD':
                    this.ExtLoopOD = this.CoilDiameter
                    break;
                case 'ID':
                    this.ExtLoopOD = this.CoilDiameter + (2 * this.WireDiameter)
                    break
                case 'MD':
                    this.ExtLoopOD = this.CoilDiameter + this.WireDiameter
                    break;
                default:
                    this.ExtLoopOD = null
            }
        }
        this.DesignErrors = null
        let design_errors = this.check_build_rules(prop)
        // TODO: this can be simplified
        if (design_errors[0] > 0) {
            this.DesignErrors = design_errors[1]
        }
        if (design_errors[1].length === 0) {
            // TODO: only necessary if we have no design issues
            if (prop) {
                console.log("validating prop...", prop, val)
                // work out what fields are used to design the spring
                console.log("this.LockOptions", this.lockOptions)
                if (!this.LockOptions) {
                    console.log("design still open", prop, val)
                    // if (isNumeric(val)) {
                    if (!this.DesignFields.includes(prop)) {
                        console.log("highlight input")
                        // highlight the input field so we know it was used.  Dynamic CSS?
                        console.log("value is numeric")
                        this.DesignFields.push(prop)
                    }
                }
            }

            console.log("design fields", this.DesignFields)
            this.DesignMode = this.getDesignMode()
            if (this.DesignMode > 0) {
                console.log("It's Django time...")
                this.calculateSpring()
                // make sure we have sufficient data to enable the costings tab
                if (this.WireGrade || this.CustomTensile) {
                    this.ValidPart = true
                }
            } else {
                console.log("insufficient params", this.DesignMode)
            }
        } else {
            console.log("we have design errors", design_errors)
        }
    }

    getDesignMode() {
        var options = 0;
        console.log("building a spring", this.WireDiameter)
        if (this.PartType === 'C' || this.PartType === 'E' || this.PartType === 'V') {

            if (this.FreeLength) {
                // we're looking for mode 1 or 2
                // mode 1 test
                if (this.WireDiameter) {
                    options = options + 1;
                }
                if (this.CoilDiameter) {
                    options = options + 1;
                }
                // coilDiameter1 is for cone springs
                if (this.PartType === 'V' && this.CoilDiameter1) {
                    options = options + 1;
                }
                if (this.TotalCoils) {
                    options = options + 1;
                }
                if (this.SpringRate) {
                    options = options + 1;
                }

                if (options >= 3) {
                    return 1; // designMode is set to 1
                }
                console.log("starting mode 2 on 517")
                // mode 2 test
                options = 0;
                if (this.springrequirements.OP1Length && this.springrequirements.OP1Load) {
                    console.log("OP1 load & length")
                    options = options + 1;
                }
                if (this.springrequirements.OP1Length && this.springrequirements.OP1Deflection) {
                    // if (isNumeric(this.OP1Length) && isNumeric(this.OP1Deflection)) {
                    options = options + 1;
                }
                if (this.springrequirements.OP1Load && this.springrequirements.OP1Deflection) {
                    // if (isNumeric(this.OP1Load) && isNumeric(this.OP1Deflection)) {
                    options = options + 1;
                }

                if (options >= 3) {
                    console.log("returning on 534")
                    return 2; // designMode is set to 2
                }
            } else {  // free length not defined
                console.log("starting mode 3 on 534")
                // mode 3 test
                options = 0;
                console.log("this.springrequirements", this.springrequirements)
                if (this.SpringRate) {
                    if (this.springrequirements.OP1Length && this.springrequirements.OP1Load) {
                        options = options + 1;
                    }
                    if (this.springrequirements.OP1Length && this.springrequirements.OP1Deflection) {
                        // if (isNumeric(this.OP1Length)
                        //     && isNumeric(this.OP1Deflection)) {
                        options = options + 1;
                    }
                    if (this.springrequirements.OP1Load && this.springrequirements.OP1Deflection) {
                        // if (isNumeric(this.OP1Load) && isNumeric(this.OP1Deflection)) {
                        options = options + 1;
                    }
                    if (this.WireDiameter) {
                        options = options + 1;
                    }
                    if (this.CoilDiameter) {
                        options = options + 1;
                    }
                    if (this.TotalCoils) {
                        options = options + 1;
                    }
                }
                if (options >= 4) {
                    console.log("returning on 564")
                    return 3; // designMode is set to 3
                }
                console.log("starting mode 4 on 568")
                // mode 4 test
                options = 0;
                console.log("OPS on 564", this.springrequirements)
                if (isNumeric(this.springrequirements.OP1Length) && isNumeric(this.springrequirements.OP1Length)) {
                    console.log("OP1length found")
                    options = options + 1;
                }
                if (isNumeric(this.springrequirements.OP1Length) && isNumeric(this.springrequirements.OP1Deflection)) {
                    // if (isNumeric(this.OP1Length) && isNumeric(this.OP1Deflection)) {
                    options = options + 1;
                }
                if (isNumeric(this.springrequirements.OP1Load) && isNumeric(this.springrequirements.OP1Deflection)) {
                    // if (isNumeric(this.OP1Load) && isNumeric(this.OP1Deflection)) {
                    options = options + 1;
                }
                if (isNumeric(this.springrequirements.OP2Length) && isNumeric(this.springrequirements.OP2Load)) {
                    console.log("OP2length found")
                    // if (isNumeric(this.OP2Load) && isNumeric(this.OP2Length)) {
                    options = options + 1;
                }
                if (isNumeric(this.springrequirements.OP2Length) && isNumeric(this.springrequirements.OP2Deflection)) {
                    // if (isNumeric(this.OP2Length) && isNumeric(this.OP2Deflection)) {
                    options = options + 1;
                }
                if (isNumeric(this.springrequirements.OP2Load) && isNumeric(this.springrequirements.OP2Deflection)) {
                    // if (isNumeric(this.OP2Load) && isNumeric(this.OP2Deflection)) {
                    options = options + 1;
                }

                if (isNumeric(this.WireDiameter)) {
                    options = options + 1;
                }
                if (isNumeric(this.CoilDiameter)) {
                    options = options + 1;
                }
                if (isNumeric(this.TotalCoils)) {
                    options = options + 1;
                }
            }
            if (options >= 4) {
                console.log("returning on 608")
                return 4; // designMode is set to 4
            }
        } else {
            // it must be a torsion spring
            if (this.PartType === 'T') {

                if (isNumeric(this.WireDiameter)) {
                    options = options + 1;
                }
                if (isNumeric(this.CoilDiameter)) {
                    options = options + 1;
                }
                if (isNumeric(this.TotalCoils)) {
                    options = options + 1;
                }
                if (isNumeric(this.SpringRate)) {
                    options = options + 1;
                }

                if (options >= 3) {
                    return 1; // designMode is set to 1
                }

                if (options >= 2 && isNumeric(this.OP1Torque)
                    && isNumeric(this.OP1Deflection))
                    return 5; // design mode 5 is unique to torsion springs
            }
        }
        // if we're here then we don't have enough data to build a spring
        console.log("returning on 637: the end")
        return 0;
    }
}

export class springrequirements {
    OP1Length = null
    OP1Torque = null
    OP1Load = null
    OP1Deflection = null
    OP1PartialAngle = null
    OP1Stress = null
    OP1StressPercent = null
    OP1BodyStress = null
    OP1LoopStress = null
    OP1InsideDiameter = null
    OP1LoadTolerance1 = null
    OP1LoadTolerance2 = null
    OP1ODExpansion = null
    OP2Length = null
    OP2Torque = null
    OP2Load = null
    OP2Deflection = null
    OP2PartialAngle = null
    OP2Stress = null
    OP2StressPercent = null
    OP2BodyStress = null
    OP2LoopStress = null
    OP2InsideDiameter = null
    OP2LoadTolerance1 = null
    OP2LoadTolerance2 = null
    OP2ODExpansion = null
    OP3Length = null
    OP3Torque = null
    OP3Load = null
    OP3Deflection = null
    OP3PartialAngle = null
    OP3Stress = null
    OP3StressPercent = null
    OP3BodyStress = null
    OP3LoopStress = null
    OP3InsideDiameter = null
    OP3LoadTolerance1 = null
    OP3LoadTolerance2 = null
    OP3ODExpansion = null
    OP4Length = null
    OP4Torque = null
    OP4Load = null
    OP4Deflection = null
    OP4PartialAngle = null
    OP4Stress = null
    OP4StressPercent = null
    OP4BodyStress = null
    OP4LoopStress = null
    OP4InsideDiameter = null
    OP4LoadTolerance1 = null
    OP4LoadTolerance2 = null
    OP4ODExpansion = null
}

export class SpringStress {

}

export class SpringResults {
    CompSolidLength = null
    CompMinLengthStatic = null
    CompMinLengthDynamic = null
    CompSolidLoad = null
    CompMaxODExpansion = null
    CompSolidStress = null
    ExtEstimatedLength = null
    ExtAddedLength = null
    ExtInitialTensionStress = null
    ExtBodyLength = null
    ExtBodyLengthMax = null
    TorBodyLengthFree = null
    TorBodyLengthMax = null
    TorMandrelDiameter = null
    TorTubeDiameter = null
    TorPartialAngle = null
    StressFactor = null
    CompActiveCoils = null
    SpringIndex = null
    CompPitch = null
    CompHelixAngle = null
    CompBuckling = null
    InsideCoilDiameter = null
    MeanCoilDiameter = null
    ExtLoopID = null
    WireLength = null
    SpringWeight = null
    NaturalFrequency = null
    tolParallelism1 = null
}

export class Tolerance_Notes {
    note = null
    expected_value = null
    tolerance_max = null
    tolerance_min = null
}