// import {nextTick} from "vue";

export class CostingObj {
    id = null
    Part = []
    Qty1 = null
    Qty2 = null
    Qty3 = null
    Qty4 = null
    Qty5 = null
    CostingMethod = 3 // default margins
    q1UnitCost = null
    q2UnitCost = null
    q3UnitCost = null
    q4UnitCost = null
    q5UnitCost = null
    q1SellCost = null
    q2SellCost = null
    q3SellCost = null
    q4SellCost = null
    q5SellCost = null
    ConfirmOps  = false
    ConfirmAncillaryItems = false
    ProductionOps = []  // a list of Ops Objects
    AncillaryItems = []  // a list of extra parts (nuts & bolts)
    ConfirmTooling = false
    ToolingCost = null
    TimeMargin = null
    FinishingMargin = null
    WasteMargin = null
    WireMargin = null
    CarriageMargin = null
    Results = {}

        // async validateCosting(opt, max_dp) {
        //     console.log("validateCosting", this.Part.PartType)
        //
        //     switch (this.Part.PartType) {
        //         case 'C':
        //         case 'E':
        //             case 'T':
        //             this.validateSpringCosting(opt, max_dp)
        //             break;
        //         case 'L':
        //             console.log(this)
        //             this.LaserCostingViable()
        //             break;
        //         case 'W':
        //             this.validateWireformCosting(opt, max_dp)
        //             break;
        //     }
        // }

}


export class FinishingObj {
    ConfirmFinishing = false
    Finisher = null
    FinishingUnitCost = null
    FinishingRate = null
    FinishingCarriage = null
}

export class InspectionObj {
    ConfirmInspection = false
    InspectionType = null
    InspectionSetup = null
    InspectionRate = null
}

export class PackingObj {
    ConfirmPackaging = false
    ManualPacking = false
    PackagingSetup = null
    PackagingRate = null
    PackingCost1 = null
    PackingCost2 = null
    PackingCost3 = null
    PackingCost4 = null
    PackingCost5 = null
}
export class CarriageObj {
    ConfirmCarriage = false
    Courier = null
    CarriageSetup = null
    CarriageRate = null
    CarriageCost1 = null
    CarriageCost2 = null
    CarriageCost3 = null
    CarriageCost4 = null
    CarriageCost5 = null
}

export class OtherObj {
    ConfirmOther = false
    ManualOther = false
    OtherType = null
    OtherSetup = null
    OtherRate = null
}
export class ExtrasObj {
    // catch all for any extra pricing
}
export class ProductionOpsObj {
    // end forming, bending, assembly...
    Op = null
    OpName = null
    SetupTime = null
    RunningTime = null
    RunningPerQuantity = 100
    OpCost = null
        // get total() {
        // // ((costingRate/60) * setupTime) + ((costingRate/60) * RunningTime *  /  RunningPerQuantity)
        //
        // return 0;
    // }
}
export class AncillaryItemsObj {
    // nuts & bolts sold to complete the part
    itemCode = null
    itemDescription = null
    itemSupplier = null
    itemLeadTime = null
    itemQuantity = null
    itemCost = null

    get total() {
        return this.Qty * this.Cost;
    }
}