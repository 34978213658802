import {calculationRelevant} from "@/store/decorators";
import {PartObj} from "@/store/PartObj";

export class WireformObj extends PartObj  {
        @calculationRelevant
    PartType = "W"       // wireform
    PartMaterial = null         // incorporate mild steel from the boolean
    WireDiameter = null
    Cuts = null
    Ops = null              // e.g. deburring
    WfStructure = []     // a bunch of BendObjs
    qMaterial = true
    qWireDiameter = true
    sfMaterial = true
    sfWireDiameter = false
    @calculationRelevant
    Units = 'M'     // metric by default
    Results = {};
}

export class BendObj { // wireInfo on old version
    actual_length = null    // either the straight length or the length of teh arc
    summary = null          // for display purposes
    length = null           // straight length, no bends
    radius = null           // combines with angle to give an arc
    angle = null            // combines with radius to give an arc
}